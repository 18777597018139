// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class SteamPropertiesByPressureAndEnthalpy extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Eigenschappen stoom naar druk en enthalpie</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1} style={{ lineHeight: "20.7999992370605px" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Deze calculator zoekt de eigenschappen van water op in het vloeibare, verzadigde
                                    stoom en oververhitte stoom gebied aan de hand van de druk en enthalpie. De input
                                    bestaat uit de druk en enthalpie van de stoom. De output bestaat uit de enthalpie,
                                    oververhitting, dichtheid, deel gas en staat van het water (vloeibaar/ mix/ gas).
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="steamcalculation">
                        <form action={null} id="calculationq" method="post" noValidate="novalidate">
                            <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                            &nbsp;
                            <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                            <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                            <div className="steamcalculation">
                                <table className="inputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Invoer gegevens</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>
                                                <strong>Eenhedenstelsel</strong>
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="ChangeTypes">
                                                    <option value="S">SI(bar)</option>
                                                    <option value="I">Imperial</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>&nbsp;</td>
                                            <td style={{ width: 82 }}>&nbsp;</td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Druk</td>
                                            <td style={{ width: 173 }}>
                                                <input
                                                    name="PressureValue"
                                                    className="inputvalue"
                                                    style={{
                                                        backgroundImage: 'url("data:image/png',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundAttachment: "scroll",
                                                        backgroundSize: "16px 18px",
                                                        backgroundPosition: "98% 50%",
                                                    }}
                                                />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="PressureType">
                                                    <option value="S;bar(a);5">bar(a)</option>
                                                    <option value="S;bar(g);5">bar(g)</option>
                                                    <option value="S;kPa(a);5">kPa(a)</option>
                                                    <option value="S;kPa(g);5">kPa(g)</option>
                                                    <option value="S;MPa(a);5">MPa(a)</option>
                                                    <option value="S;MPa(g);5">MPa(g)</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PressureError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Enthalpie</td>
                                            <td style={{ width: 173 }}>
                                                <input name="EnthalphyValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="EnthalphyType">
                                                    <option value="kJkg">kJ/kg</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="EnthalphyError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ textAlign: "center" }}>
                                    <br />
                                    <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                                </div>
                                <table className="outputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Resultaat</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Temperatuur
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="TemperatureValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="TemperatureType">
                                                    <option value="S;K;0">K</option>
                                                    <option value="S;C;0">°C</option>
                                                    <option value="S;F;0">°F</option>
                                                    <option value="S;R;0">°R</option>
                                                    <option value="S;Re;0">°Re</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="TemperatureError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Oververhitting
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="SuperHeatedValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="SuperHeatedType">
                                                    <option value="S;K;0">K</option>
                                                    <option value="S;C;0">°C</option>
                                                    <option value="S;F;0">°F</option>
                                                    <option value="S;R;0">°R</option>
                                                    <option value="S;Re;0">°Re</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="SuperHeatedError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Dichtheid
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="DensityValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="DensityType">
                                                    <option value="S;kg/m3;5">kg/m³</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="DensityError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Entropie
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="EntropyValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="EntropyType">
                                                    <option value="S;kJ/kgK;4">kJ/kgK</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="EntropyError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Percentage gas
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="PercentageGasValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="PercentageGasType">
                                                    <option value="S;%;2">%</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="PercentageGasError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                IF97 Regio
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="IF97RegionValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>&nbsp;</td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="IF97RegionError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>Fase</td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="PhaseValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>&nbsp;</td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="PhaseError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "35%" }}>
                                                Isobare warmtecapaciteit
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "20%" }}>
                                                <input name="IsobaricHeatCapacityValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "10%" }}>
                                                <select name="IsobaricHeatCapacityType">
                                                    <option value="S;kJ/kg;2">kJ/kg</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "35%" }}>
                                                <div className="errormessage" id="IsobaricHeatCapacityError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "35%" }}>
                                                Geluidssnelheid
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "20%" }}>
                                                <input name="SpeedOfSoundValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "10%" }}>
                                                <select name="SpeedOfSoundType">
                                                    <option value="S;m/s;5">m/s</option>
                                                    <option value="S;km/h;5">km/h</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "35%" }}>
                                                <div className="errormessage" id="SpeedOfSoundError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
