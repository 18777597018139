// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class ConversionTableCapacity extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Omrekentabel capaciteit</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Rekent capaciteit om in de volgende eenheden: m³/u, m³/min, m³/s, l/u, l/min, l/s,
                                    Imperial gph, Imperial gpm, Imperial gps, US gph, US gpm, US gps
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <form action={null} id="calculationv" method="post" noValidate="novalidate">
                        <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                        &nbsp;
                        <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                        <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                        <input name="ChangeTypes" type="hidden" defaultValue="S" />
                        <div className="steamcalculation">
                            <table className="inputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Invoer gegevens</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 292 }}>Capaciteit</td>
                                        <td style={{ width: 173 }}>
                                            <input
                                                name="CapacityValue"
                                                className="inputvalue"
                                                style={{
                                                    backgroundImage: 'url("data:image/png',
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundAttachment: "scroll",
                                                    backgroundSize: "16px 18px",
                                                    backgroundPosition: "98% 50%",
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: 97 }}>
                                            <select name="CapacityType">
                                                <option value="m3h">m³/h</option>
                                                <option value="m3min">m³/min</option>
                                                <option value="m3s">m³/s</option>
                                                <option value="lh">l/h</option>
                                                <option value="lmin">l/min</option>
                                                <option value="ls">l/s</option>
                                                <option value="impgph">imperial gph</option>
                                                <option value="impgpm">imperial gpm</option>
                                                <option value="impgps">imperial gps</option>
                                                <option value="usgph">US gph</option>
                                                <option value="usgpm">US gpm</option>
                                                <option value="usgps">US gps</option>
                                            </select>
                                        </td>
                                        <td style={{ width: 291 }}>
                                            <div className="errormessage" id="CapacityError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ textAlign: "center" }}>
                                <br />
                                <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                            </div>
                            <table className="outputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Resultaat</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 290 }}>
                                            Capaciteit in kubieke meters per uur
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Capacity0Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 101 }}>
                                            m³/u
                                            <input name="Capacity0Type" type="hidden" defaultValue="S;m3/h;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 289 }}>
                                            <div className="errormessage" id="Capacity0Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 290 }}>
                                            Capaciteit in kubieke meters per minuut
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Capacity1Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 101 }}>
                                            m³/min
                                            <input name="Capacity1Type" type="hidden" defaultValue="S;m3/min;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 289 }}>
                                            <div className="errormessage" id="Capacity1Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 290 }}>
                                            Capaciteit in kubieke meters per seconde
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Capacity2Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 101 }}>
                                            m³/s
                                            <input name="Capacity2Type" type="hidden" defaultValue="S;m3/s;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 289 }}>
                                            <div className="errormessage" id="Capacity2Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 290 }}>
                                            Capaciteit in liters per uur
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Capacity3Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 101 }}>
                                            l/u
                                            <input name="Capacity3Type" type="hidden" defaultValue="S;l/h;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 289 }}>
                                            <div className="errormessage" id="Capacity3Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 290 }}>
                                            Capaciteit in liters per minuut
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Capacity4Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 101 }}>
                                            l/min
                                            <input name="Capacity4Type" type="hidden" defaultValue="S;l/min;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 289 }}>
                                            <div className="errormessage" id="Capacity4Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 290 }}>
                                            Capaciteit in liters per seconde
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Capacity5Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 101 }}>
                                            l/s
                                            <input name="Capacity5Type" type="hidden" defaultValue="S;l/s;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 289 }}>
                                            <div className="errormessage" id="Capacity5Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 290 }}>
                                            Capaciteit in imperial gph
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Capacity6Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 101 }}>
                                            imp. gph
                                            <input name="Capacity6Type" type="hidden" defaultValue="S;imperial gph;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 289 }}>
                                            <div className="errormessage" id="Capacity6Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 290 }}>
                                            Capaciteit in imperial gpm
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Capacity7Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 101 }}>
                                            imp. gpm
                                            <input name="Capacity7Type" type="hidden" defaultValue="S;imperial gpm;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 289 }}>
                                            <div className="errormessage" id="Capacity7Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 290 }}>
                                            Capaciteit in imperial gps
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Capacity8Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 101 }}>
                                            imp. gps
                                            <input name="Capacity8Type" type="hidden" defaultValue="S;imperial gps;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 289 }}>
                                            <div className="errormessage" id="Capacity8Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 290 }}>
                                            Capaciteit in US gph
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Capacity9Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 101 }}>
                                            US gph
                                            <input name="Capacity9Type" type="hidden" defaultValue="S;US gph;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 289 }}>
                                            <div className="errormessage" id="Capacity9Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 290 }}>
                                            Capaciteit in US gpm
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Capacity10Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 101 }}>
                                            US gpm
                                            <input name="Capacity10Type" type="hidden" defaultValue="S;US gpm;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 289 }}>
                                            <div className="errormessage" id="Capacity10Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 290 }}>
                                            Capaciteit in US gps
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Capacity11Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 101 }}>
                                            US gps
                                            <input name="Capacity11Type" type="hidden" defaultValue="S;US gps;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 289 }}>
                                            <div className="errormessage" id="Capacity11Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
