// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class ConversionTableLength extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Omrekentabel lengte</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1} style={{ lineHeight: "20.7999992370605px" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Rekent lengtes om in de volgende eenheden: meter, centimeter, millimeter, inch,
                                    voet, yard, mijl, zeemijl
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <form action={null} id="calculationr" method="post" noValidate="novalidate">
                        <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                        &nbsp;
                        <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                        <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                        <input name="ChangeTypes" type="hidden" defaultValue="S" />
                        <div className="steamcalculation">
                            <table className="inputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Invoer gegevens</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 298 }}>Lengte</td>
                                        <td style={{ width: 173 }}>
                                            <input
                                                name="LengthValue"
                                                className="inputvalue"
                                                style={{
                                                    backgroundImage: 'url("data:image/png',
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundAttachment: "scroll",
                                                    backgroundSize: "16px 18px",
                                                    backgroundPosition: "98% 50%",
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: 82 }}>
                                            <select name="LengthType">
                                                <option value="m">m</option>
                                                <option value="cm">cm</option>
                                                <option value="mm">mm</option>
                                                <option value="in">in</option>
                                                <option value="ft">ft</option>
                                                <option value="yd">yd</option>
                                                <option value="mi">mi</option>
                                                <option value="nmi">nmi</option>
                                                <option value="nmi">nmi</option>
                                            </select>
                                        </td>
                                        <td style={{ width: 300 }}>
                                            <div className="errormessage" id="LengthError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ textAlign: "center" }}>
                                <br />
                                <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                            </div>
                            <table className="outputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Resultaat</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Lengte in meters
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Length0Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            m<input name="Length0Type" type="hidden" defaultValue="S;m;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Length0Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Lengte in centimeters
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Length1Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            cm
                                            <input name="Length1Type" type="hidden" defaultValue="S;cm;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Length1Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Lengte in millimeters
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Length2Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            mm
                                            <input name="Length2Type" type="hidden" defaultValue="S;mm;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Length2Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Lengte in inches
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Length3Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            in
                                            <input name="Length3Type" type="hidden" defaultValue="S;in;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Length3Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Lengte in feet
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Length4Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            ft
                                            <input name="Length4Type" type="hidden" defaultValue="S;ft;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Length4Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Lengte in yards
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Length5Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            yd
                                            <input name="Length5Type" type="hidden" defaultValue="S;yd;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Length5Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Lengte in mijlen
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Length6Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            mi
                                            <input name="Length6Type" type="hidden" defaultValue="S;mi;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Length6Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Lengte in zeemijlen
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Length7Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            nmi
                                            <input name="Length7Type" type="hidden" defaultValue="S;nmi;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Length7Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
