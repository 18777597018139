// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class FlashSteamInCondensateReturnPipes extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Naverdampstoom bij condensaatafvoer</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1} style={{ lineHeight: "20.7999992370605px" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Deze calculator berekent de hoeveelheid naverdampstoom bij het afvoeren van
                                    condensaat. De input bestaat uit de druk en temperatuur voor de condenspot, de druk
                                    na de condenspot en het debiet van het condensaat. De output bestaat uit het gewicht
                                    aan condensaat en naverdampstoom per uur gecombineerd met het volume wat het
                                    naverdampstoom inneemt.
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>
                                        Aannames: Deze berekening gaat er van uit dat alle energie tijdens het verlagen
                                        van de druk behouden blijft.
                                    </strong>
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <form action={null} id="calculatione" method="post" noValidate="novalidate">
                        <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                        &nbsp;
                        <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                        <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                        <div className="steamcalculation">
                            <table className="inputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Invoer gegevens</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 299 }}>&nbsp;</td>
                                        <td style={{ width: 173 }}>
                                            <strong>Eenhedenstelsel</strong>
                                        </td>
                                        <td style={{ width: 82 }}>
                                            <select name="ChangeTypes">
                                                <option value="S">SI(bar)</option>
                                                <option value="I">Imperial</option>
                                            </select>
                                        </td>
                                        <td style={{ width: 299 }}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 299 }}>&nbsp;</td>
                                        <td style={{ width: 173 }}>&nbsp;</td>
                                        <td style={{ width: 82 }}>&nbsp;</td>
                                        <td style={{ width: 299 }}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 299 }}>Druk vóór de condenspot</td>
                                        <td style={{ width: 173 }}>
                                            <input
                                                name="PressureBeforeSteamTrapValue"
                                                className="inputvalue"
                                                style={{
                                                    backgroundImage: 'url("data:image/png',
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundAttachment: "scroll",
                                                    backgroundSize: "16px 18px",
                                                    backgroundPosition: "98% 50%",
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: 82 }}>
                                            <select name="PressureBeforeSteamTrapType">
                                                <option value="S;bar(g)">bar(g)</option>
                                                <option value="S;bar(a)">bar(a)</option>
                                                <option value="S;kPa(g)">kPa(g)</option>
                                                <option value="S;kPa(a)">kPa(a)</option>
                                                <option value="S;MPa(g)">MPa(g)</option>
                                                <option value="S;MPa(a)">MPa(a)</option>
                                            </select>
                                        </td>
                                        <td style={{ width: 299 }}>
                                            <div className="errormessage" id="PressureBeforeSteamTrapError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 299 }}>
                                            Temperatuur vóór de condenspot&nbsp;<em>(stoomtabel)</em>
                                        </td>
                                        <td style={{ width: 173 }}>
                                            <input name="TemperatureBeforeSteamTrapValue" className="inputvalue" />
                                        </td>
                                        <td style={{ width: 82 }}>
                                            <select name="TemperatureBeforeSteamTrapType">
                                                <option value="S;C;1;-">°C</option>
                                                <option value="S;K;1;-">K</option>
                                                <option value="S;F;1;-">°F</option>
                                                <option value="S;R;1;-">°R</option>
                                                <option value="S;Re;1;-">°Re</option>
                                            </select>
                                        </td>
                                        <td style={{ width: 299 }}>
                                            <div className="errormessage" id="TemperatureBeforeSteamTrapError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 299 }}>Druk na de condenspot</td>
                                        <td style={{ width: 173 }}>
                                            <input name="PressureAfterSteamTrapValue" className="inputvalue" />
                                        </td>
                                        <td style={{ width: 82 }}>
                                            <select name="PressureAfterSteamTrapType">
                                                <option value="S;bar(g)">bar(g)</option>
                                                <option value="S;bar(a)">bar(a)</option>
                                                <option value="S;kPa(g)">kPa(g)</option>
                                                <option value="S;kPa(a)">kPa(a)</option>
                                                <option value="S;MPa(g)">MPa(g)</option>
                                                <option value="S;MPa(a)">MPa(a)</option>
                                            </select>
                                        </td>
                                        <td style={{ width: 299 }}>
                                            <div className="errormessage" id="PressureAfterSteamTrapError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 299 }}>Condensaat debiet</td>
                                        <td style={{ width: 173 }}>
                                            <input name="FlowCondensateValue" className="inputvalue" />
                                        </td>
                                        <td style={{ width: 82 }}>
                                            <select name="FlowCondensateType">
                                                <option value="S;kg/h">kg/h</option>
                                                <option value="S;kg/s">kg/s</option>
                                            </select>
                                        </td>
                                        <td style={{ width: 299 }}>
                                            <div className="errormessage" id="FlowCondensateError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ textAlign: "center" }}>
                                <br />
                                <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                            </div>
                            <table className="outputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Resultaat</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Debiet condensaat
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="FlowCondensateValue" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            <select name="FlowCondensateType">
                                                <option value="S;kg/h;0">kg/h</option>
                                                <option value="S;kg/s;3">kg/s</option>
                                            </select>
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="FlowCondensateError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Debiet naverdampstoom
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="FlowFlashSteamValue" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            <select name="FlowFlashSteamType">
                                                <option value="S;kg/h;0">kg/h</option>
                                                <option value="S;kg/s;3">kg/s</option>
                                            </select>
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="FlowFlashSteamError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Volume naverdampstoom
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="VolumeFlashSteamValue" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            <select name="VolumeFlashSteamType">
                                                <option value="S;m3h;1">m³/h</option>
                                                <option value="S;m3min;3">m³/min</option>
                                                <option value="S;m3s;4">m³/s</option>
                                                <option value="S;lh;1">l/h</option>
                                                <option value="S;lmin;3">l/min</option>
                                                <option value="S;ls;4">l/s</option>
                                            </select>
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="VolumeFlashSteamError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
