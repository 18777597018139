// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class MinimalCondensatePipeDiameter extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Minimale pijpdiameter condensleiding</h1>
                <div id="nmpContent">
                    <div className="steamcalculation">
                        <table border={0} cellPadding={1} cellSpacing={1} style={{ lineHeight: "20.7999992370605px" }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <Link to="/">
                                            <img
                                                src={BackButtonImage}
                                                style={{ float: "right", height: 30, width: 100 }}
                                            />
                                        </Link>
                                        <div>&nbsp;</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Deze calculator berekent de gewenste diameter bij een gegeven stroomsnelheid. De
                                        output bestaat uit de minimale diameter die nodig is om de gewenste snelheden te
                                        halen en de dichtstbijzijnde grotere diameter.
                                        <br />
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>
                                            Aannames: Bij deze berekening wordt er van uit gegaan dat het condensaat en
                                            de dampstoom met de opgegeven snelheden stromen. Veelgebruikte waardes voor
                                            de stroomsnelheden zijn:&nbsp;&nbsp; &nbsp;
                                        </strong>
                                        <ul>
                                            <li>
                                                <strong>stroomsnelheid naverdampstoom: 15 m/s</strong>
                                            </li>
                                            <li>
                                                <strong>
                                                    <strong>stroomsnelheid condensaat: 1,5 m/s</strong>
                                                </strong>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                        algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                        <a
                                            href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                            style={{ lineHeight: "20.7999992370605px" }}
                                        >
                                            stoom@eriks.nl
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <form action={null} id="calculationd" method="post" noValidate="novalidate">
                            <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                            &nbsp;
                            <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                            <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                            <div className="steamcalculation">
                                <table className="inputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Invoer gegevens</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>
                                                <strong>Eenhedenstelsel</strong>
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="ChangeTypes">
                                                    <option value="S">SI(bar)</option>
                                                    <option value="I">Imperial</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>&nbsp;</td>
                                            <td style={{ width: 82 }}>&nbsp;</td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Stroomsnelheid naverdampstoom</td>
                                            <td style={{ width: 173 }}>
                                                <input
                                                    name="FlowspeedFlashSteamValue"
                                                    className="inputvalue"
                                                    style={{
                                                        backgroundImage: 'url("data:image/png',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundAttachment: "scroll",
                                                        backgroundSize: "16px 18px",
                                                        backgroundPosition: "98% 50%",
                                                    }}
                                                />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="FlowspeedFlashSteamType">
                                                    <option value="S;m/s;5">m/s</option>
                                                    <option value="S;km/h;5">km/h</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="FlowspeedFlashSteamError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Stroomsnelheid condensaat</td>
                                            <td style={{ width: 173 }}>
                                                <input name="FlowspeedCondensateValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="FlowspeedCondensateType">
                                                    <option value="S;m/s;5">m/s</option>
                                                    <option value="S;km/h;5">km/h</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="FlowspeedCondensateError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Druk op het stoomnet</td>
                                            <td style={{ width: 173 }}>
                                                <input name="PressureSteamNetValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="PressureSteamNetType">
                                                    <option value="S;bar(g);5">bar(g)</option>
                                                    <option value="S;bar(a);5">bar(a)</option>
                                                    <option value="S;kPa(g);5">kPa(g)</option>
                                                    <option value="S;kPa(a);5">kPa(a)</option>
                                                    <option value="S;MPa(g);5">MPa(g)</option>
                                                    <option value="S;MPa(a);5">MPa(a)</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PressureSteamNetError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>
                                                Temperatuur vóór de condenspot <em>(stoomtabel)</em>
                                            </td>
                                            <td style={{ width: 173 }}>
                                                <input name="TemperatureBeforeSteamTrapValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="TemperatureBeforeSteamTrapType">
                                                    <option value="S;C;1;-">°C</option>
                                                    <option value="S;K;1;-">K</option>
                                                    <option value="S;F;1;-">°F</option>
                                                    <option value="S;R;1;-">°R</option>
                                                    <option value="S;Re;1;-">°Re</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="TemperatureBeforeSteamTrapError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Druk op het condensaatnet</td>
                                            <td style={{ width: 173 }}>
                                                <input name="PressureCondensateNetValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="PressureCondensateNetType">
                                                    <option value="S;bar(g);5">bar(g)</option>
                                                    <option value="S;bar(a);5">bar(a)</option>
                                                    <option value="S;kPa(g);5">kPa(g)</option>
                                                    <option value="S;kPa(a);5">kPa(a)</option>
                                                    <option value="S;MPa(g);5">MPa(g)</option>
                                                    <option value="S;MPa(a);5">MPa(a)</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PressureCondensateNetError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Debiet</td>
                                            <td style={{ width: 173 }}>
                                                <input name="FlowValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="FlowType">
                                                    <option value="S;kg/h">kg/h</option>
                                                    <option value="S;kg/s">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="FlowError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ textAlign: "center" }}>
                                    <br />
                                    <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                                </div>
                                <table className="outputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Resultaat</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Minimale diameter condensaatleiding
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="MinimalDiameterCodensatePipeValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="MinimalDiameterCodensatePipeType">
                                                    <option value="S;mm;0">mm</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="MinimalDiameterCodensatePipeError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Dichtstbijzijnde grotere diameter leiding
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input
                                                    name="NearestDiameterCondensatePipeValue"
                                                    defaultValue
                                                    readOnly
                                                />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="NearestDiameterCondensatePipeType">
                                                    <option value="S;mm;0">mm</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="NearestDiameterCondensatePipeError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
