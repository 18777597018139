import React, { Component } from "react";
import logo from "../res/images/logo.jpg";
import MainPage from "./MainPage";
import { BrowserRouter as Router, Route } from "react-router-dom";
import NavigationMenu from "./NavigationMenu";
import ConversionTableVelocity from "./pages/ConversionTableVelocity";
import DiameterAtAGivenPressureLoss from "./pages/DiameterAtAGivenPressureLoss";
import DiameterAtAGivenVelocity from "./pages/DiameterAtAGivenVelocity";
import PressureLossInASteamPipe from "./pages/PressureLossInASteamPipe";
import MinimalCondensatePipeDiameter from "./pages/MinimalCondensatePipeDiameter";
import FlashSteamInCondensateReturnPipes from "./pages/FlashSteamInCondensateReturnPipes";
import KvCalculationForSaturatedSteam from "./pages/KvCalculationForSaturatedSteam";
import KvCalculationForSuperheatedSteam from "./pages/KvCalculationForSuperheatedSteam";
import KvCalculationForWater from "./pages/KvCalculationForWater";
import SteamQualityByPressureReduction from "./pages/SteamQualityByPressureReduction";
import StartUpAndOperatingLossesOfASteamPipe from "./pages/StartUpAndOperatingLossesOfASteamPipe";
import ImpactOfAirOnTheTemperatureInASteamSystem from "./pages/ImpactOfAirOnTheTemperatureInASteamSystem";
import PowerToSteamUsage from "./pages/PowerToSteamUsage";
import SteamUsageOfAHeatExchanger from "./pages/SteamUsageOfAHeatExchanger";
import SaturatedSteamTablesByPressure from "./pages/SaturatedSteamTablesByPressure";
import SaturatedSteamTablesByTemperature from "./pages/SaturatedSteamTablesByTemperature";
import SteamPropertiesByPressureAndTemperature from "./pages/SteamPropertiesByPressureAndTemperature";
import SteamPropertiesByPressureAndEnthalpy from "./pages/SteamPropertiesByPressureAndEnthalpy";
import ConversionTableLength from "./pages/ConversionTableLength";
import ConversionTablePressure from "./pages/ConversionTablePressure";
import ConversionTableEnergy from "./pages/ConversionTableEnergy";
import ConversionTableCapacity from "./pages/ConversionTableCapacity";
import ConversionTableTemperature from "./pages/ConversionTableTemperature";
import ConversionTableFlow from "./pages/ConversionTableFlow";

export default class App extends Component {
    // Public functions

    public render(): JSX.Element {
        return (
            <div className="App">
                <div className="container">
                    <div id="header">
                        <div id="logo-block">
                            <a href="/">
                                <img src={logo} style={{ width: "320px" }} alt="ERIKS" title="ERIKS" />
                            </a>
                        </div>
                    </div>
                </div>
                <div id="content">
                    <Router>
                        <Route exact path="/" component={MainPage} />
                        <Route exact path="/*/734" component={DiameterAtAGivenPressureLoss} />
                        <Route exact path="/*/735" component={DiameterAtAGivenVelocity} />
                        <Route exact path="/*/736" component={PressureLossInASteamPipe} />
                        <Route exact path="/*/737" component={MinimalCondensatePipeDiameter} />
                        <Route exact path="/*/738" component={FlashSteamInCondensateReturnPipes} />
                        <Route exact path="/*/739" component={KvCalculationForSaturatedSteam} />
                        <Route exact path="/*/740" component={KvCalculationForSuperheatedSteam} />
                        <Route exact path="/*/741" component={KvCalculationForWater} />
                        <Route exact path="/*/742" component={SteamQualityByPressureReduction} />
                        <Route exact path="/*/743" component={StartUpAndOperatingLossesOfASteamPipe} />
                        <Route exact path="/*/744" component={ImpactOfAirOnTheTemperatureInASteamSystem} />
                        <Route exact path="/*/745" component={PowerToSteamUsage} />
                        <Route exact path="/*/746" component={SteamUsageOfAHeatExchanger} />
                        <Route exact path="/*/747" component={SaturatedSteamTablesByPressure} />
                        <Route exact path="/*/748" component={SaturatedSteamTablesByTemperature} />
                        <Route exact path="/*/749" component={SteamPropertiesByPressureAndTemperature} />
                        <Route exact path="/*/750" component={SteamPropertiesByPressureAndEnthalpy} />
                        <Route exact path="/*/751" component={ConversionTableLength} />
                        <Route exact path="/*/752" component={ConversionTablePressure} />
                        <Route exact path="/*/753" component={ConversionTableVelocity} />
                        <Route exact path="/*/754" component={ConversionTableEnergy} />
                        <Route exact path="/*/755" component={ConversionTableCapacity} />
                        <Route exact path="/*/756" component={ConversionTableTemperature} />
                        <Route exact path="/*/757" component={ConversionTableFlow} />
                        <NavigationMenu />
                    </Router>
                </div>
            </div>
        );
    }
}