// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class ConversionTableEnergy extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Omrekentabel energie</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Rekent energie om in de volgende eenheden: kJ, kcal, kWh, BTU
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <form action={null} id="calculationu" method="post" noValidate="novalidate">
                        <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                        &nbsp;
                        <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                        <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                        <input name="ChangeTypes" type="hidden" defaultValue="S" />
                        <div className="steamcalculation">
                            <table className="inputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Invoer gegevens</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 299 }}>Energie</td>
                                        <td style={{ width: 173 }}>
                                            <input
                                                name="EnergyValue"
                                                className="inputvalue"
                                                style={{
                                                    backgroundImage: 'url("data:image/png',
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundAttachment: "scroll",
                                                    backgroundSize: "16px 18px",
                                                    backgroundPosition: "98% 50%",
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: 82 }}>
                                            <select name="EnergyType">
                                                <option value="kJ">kJ</option>
                                                <option value="kcal">kcal</option>
                                                <option value="kWh">kWh</option>
                                                <option value="BTU">BTU</option>
                                            </select>
                                        </td>
                                        <td style={{ width: 299 }}>
                                            <div className="errormessage" id="EnergyError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ textAlign: "center" }}>
                                <br />
                                <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                            </div>
                            <table className="outputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Resultaat</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Energie in kilojoules
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Energy0Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            kJ
                                            <input name="Energy0Type" type="hidden" defaultValue="S;kJ;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Energy0Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Energie in kilocaloriën
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Energy1Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            kcal
                                            <input name="Energy1Type" type="hidden" defaultValue="S;kcal;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Energy1Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Energie in kilowattuur
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Energy2Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            kWh
                                            <input name="Energy2Type" type="hidden" defaultValue="S;kWh;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Energy2Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Energie in british thermal units
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Energy3Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            BTU
                                            <input name="Energy3Type" type="hidden" defaultValue="S;BTU;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Energy3Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
