// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class ConversionTableFlow extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Omrekentabel massa debiet</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Rekent massa debiet om in de volgende eenheden: kg/h, kg/s, lb/h, lb/s
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <form action id="calculationx" method="post" noValidate="novalidate">
                        <input name="RequestLanguage" type="hidden" defaultValue="NL" />{" "}
                        <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                        <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                        <input name="ChangeTypes" type="hidden" defaultValue="S" />
                        <div className="steamcalculation">
                            <table className="inputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Invoer gegevens</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "35%" }}>Massa debiet</td>
                                        <td style={{ width: "20%" }}>
                                            <input
                                                name="FlowMassValue"
                                                className="inputvalue"
                                                style={{
                                                    backgroundImage: 'url("data:image/png',
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundAttachment: "scroll",
                                                    backgroundSize: "16px 18px",
                                                    backgroundPosition: "98% 50%",
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: "10%" }}>
                                            <select name="FlowMassType">
                                                <option value="kg/h">kg/u</option>
                                                <option value="kg/s">kg/s</option>
                                                <option value="lb/h">lb/u</option>
                                                <option value="lb/s">lb/s</option>
                                            </select>
                                        </td>
                                        <td style={{ width: "35%" }}>
                                            <div className="errormessage" id="FlowMassError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ textAlign: "center" }}>
                                <br />
                                <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                            </div>
                            <table className="outputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Resultaat</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "35%" }}>
                                            Massa debiet in kilogram per uur
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "20%" }}>
                                            <input name="FlowMass0Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "10%" }}>
                                            kg/u
                                            <input name="FlowMass0Type" type="hidden" defaultValue="S;kg/h;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "35%" }}>
                                            <div className="errormessage" id="FlowMass0Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "35%" }}>
                                            Massa debiet in kilogram per seconde
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "20%" }}>
                                            <input name="FlowMass1Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "10%" }}>
                                            kg/s
                                            <input name="FlowMass1Type" type="hidden" defaultValue="S;kg/s;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "35%" }}>
                                            <div className="errormessage" id="FlowMass1Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "35%" }}>
                                            Massa debiet in pounds per uur
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "20%" }}>
                                            <input name="FlowMass2Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "10%" }}>
                                            lb/u
                                            <input name="FlowMass2Type" type="hidden" defaultValue="S;lb/h;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "35%" }}>
                                            <div className="errormessage" id="FlowMass2Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "35%" }}>
                                            Massa debiet in pounds per seconde
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "20%" }}>
                                            <input name="FlowMass3Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "10%" }}>
                                            lb/s
                                            <input name="FlowMass3Type" type="hidden" defaultValue="S;lb/s;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: "35%" }}>
                                            <div className="errormessage" id="FlowMass3Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
