// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class SteamQualityByPressureReduction extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Stoomkwaliteit door drukverlaging</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1} style={{ lineHeight: "20.7999992370605px" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Deze calculator berekent het effect van drukverlaging op de kwaliteit van stoom.
                                    Indien de drukverlaging een oververhitting van het stoom veroorzaakt wordt tevens de
                                    hoeveelheid water gegeven die nodig is om het oververhitte stoom te desuperheaten.
                                    De input is de in- en uitgaande druk, de ingaande kwaliteit en het debiet. De output
                                    is de uitgaande kwaliteit, de oververhitting en de hoeveelheid water die nodig is
                                    voor desuperheaten.
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>
                                        Aannames: Deze berekening gaat er van uit dat alle energie tijdens het verlagen
                                        van de druk behouden blijft. Daarnaast gaat deze berekening er van uit dat het
                                        ingespoten water volledig verdampt.
                                    </strong>
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="steamcalculation">
                        <form action={null} id="calculationi" method="post" noValidate="novalidate">
                            <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                            &nbsp;
                            <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                            <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                            <div className="steamcalculation">
                                <table className="inputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Invoer gegevens</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>
                                                <strong>Eenhedenstelsel</strong>
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="ChangeTypes">
                                                    <option value="S">SI(bar)</option>
                                                    <option value="I">Imperial</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>&nbsp;</td>
                                            <td style={{ width: 82 }}>&nbsp;</td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Ingaande stoomdruk</td>
                                            <td style={{ width: 173 }}>
                                                <input
                                                    name="InboundSteamPressureValue"
                                                    className="inputvalue"
                                                    style={{
                                                        backgroundImage: 'url("data:image/png',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundAttachment: "scroll",
                                                        backgroundSize: "16px 18px",
                                                        backgroundPosition: "98% 50%",
                                                    }}
                                                />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="InboundSteamPressureType">
                                                    <option value="S;bar(g);5">bar(g)</option>
                                                    <option value="S;bar(a);5">bar(a)</option>
                                                    <option value="S;kPa(g);5">kPa(g)</option>
                                                    <option value="S;kPa(a);5">kPa(a)</option>
                                                    <option value="S;MPa(g);5">MPa(g)</option>
                                                    <option value="S;MPa(a);5">MPa(a)</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="InboundSteamPressureError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Kwaliteit van de ingaande stoom (factor)</td>
                                            <td style={{ width: 173 }}>
                                                <input name="QualityInboundSteamValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>&nbsp;</td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="QualityInboundSteamError">
                                                    (waarde tussen 0 en 1, bijv. 0,96 = 4% vocht)
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Uitgaande stoomdruk</td>
                                            <td style={{ width: 173 }}>
                                                <input name="OutboundSteamPressureValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="OutboundSteamPressureType">
                                                    <option value="S;bar(g);5">bar(g)</option>
                                                    <option value="S;bar(a);5">bar(a)</option>
                                                    <option value="S;kPa(g);5">kPa(g)</option>
                                                    <option value="S;kPa(a);5">kPa(a)</option>
                                                    <option value="S;MPa(g);5">MPa(g)</option>
                                                    <option value="S;MPa(a);5">MPa(a)</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="OutboundSteamPressureError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Debiet stoom</td>
                                            <td style={{ width: 173 }}>
                                                <input name="SteamFlowValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="SteamFlowType">
                                                    <option value="S;kg/h">kg/h</option>
                                                    <option value="S;kg/s">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="SteamFlowError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ textAlign: "center" }}>
                                    <br />
                                    <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                                </div>
                                <table className="outputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Resultaat</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Kwaliteit van de uitgaande stoom (x)
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="QualityOutboundSteamValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="QualityOutboundSteamType">
                                                    <option value="S; ;2">factor</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="QualityOutboundSteamError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Aantal graden oververhitting
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="DegreeSuperheatedValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="DegreeSuperheatedType">
                                                    <option value="S;K;0">K</option>
                                                    <option value="S;C;0">°C</option>
                                                    <option value="S;F;0">°F</option>
                                                    <option value="S;R;0">°R</option>
                                                    <option value="S;Re;0">°Re</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="DegreeSuperheatedError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Hoeveelheid water (20°C) nodig voor desuperheaten
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="RequiredWaterDesuperheatingValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="RequiredWaterDesuperheatingType">
                                                    <option value="S;kg/h;0">kg/h</option>
                                                    <option value="S;kg/s;3">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="RequiredWaterDesuperheatingError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
