// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class PressureLossInASteamPipe extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Druk eind leiding, droog verzadigd stoom</h1>
                <div id="nmpContent">
                    <div className="steamcalculation">
                        <table border={0} cellPadding={1} cellSpacing={1} style={{ lineHeight: "20.7999992370605px" }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <Link to="/">
                                            <img
                                                src={BackButtonImage}
                                                style={{ float: "right", height: 30, width: 100 }}
                                            />
                                        </Link>
                                        <div>&nbsp;</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Deze calculator berekent de drukval in een stoomleiding, bij een gegeven
                                        diameter. Door de berekende drukval te vergelijken met uw actuele drukval kan
                                        bekeken worden hoe vervuild de stoomleiding is. De output bestaat uit de druk
                                        aan het eind van de leiding gecombineerd met de stroomsnelheid aan het begin van
                                        de leiding.
                                        <br />
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>
                                            Aannames: Bij deze berekening is er uit gegaan van een gebruikte, rechte,
                                            stalen leiding, zonder kleppen of andere appendages in de leiding. Mocht uw
                                            leiding bochten, appendages of zware vervuiling bevatten dan moet u er
                                            rekening mee houden dat de einddruk lager uit zal vallen.
                                        </strong>
                                        <br />
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                        algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                        <a
                                            href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                            style={{ lineHeight: "20.7999992370605px" }}
                                        >
                                            stoom@eriks.nl
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        &nbsp;
                        <form action={null} id="calculationc" method="post" noValidate="novalidate">
                            <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                            &nbsp;
                            <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                            <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                            <div className="steamcalculation">
                                <table className="inputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Invoer gegevens</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>
                                                <strong>Eenhedenstelstel</strong>
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="ChangeTypes">
                                                    <option value="S">SI(bar)</option>
                                                    <option value="I">Imperial</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>&nbsp;</td>
                                            <td style={{ width: 82 }}>&nbsp;</td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Druk begin leiding</td>
                                            <td style={{ width: 173 }}>
                                                <input
                                                    name="PressurePipeStartValue"
                                                    className="inputvalue"
                                                    style={{
                                                        backgroundImage: 'url("data:image/png',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundAttachment: "scroll",
                                                        backgroundSize: "16px 18px",
                                                        backgroundPosition: "98% 50%",
                                                    }}
                                                />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="PressurePipeStartType" defaultValue={"S;bar(g)"}>
                                                    <option value="S;bar(g)">bar(g)</option>
                                                    <option value="S;bar(a)">bar(a)</option>
                                                    <option value="S;kPa(g)">kPa(g)</option>
                                                    <option value="S;kPa(a)">kPa(a)</option>
                                                    <option value="S;MPa(g)">MPa(g)</option>
                                                    <option value="S;MPa(a)">MPa(a)</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PressurePipeStartError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Debiet stoom</td>
                                            <td style={{ width: 173 }}>
                                                <input name="SteamFlowValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="SteamFlowType">
                                                    <option value="S;kg/h">kg/h</option>
                                                    <option value="S;kg/s">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="SteamFlowError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Lengte leiding</td>
                                            <td style={{ width: 173 }}>
                                                <input name="PipeLengthValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="PipeLengthType">
                                                    <option value="S;m">m</option>
                                                    <option value="S;cm">cm</option>
                                                    <option value="S;mm">mm</option>
                                                    <option value="S;nmi">nmi</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PipeLengthError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Diameter</td>
                                            <td style={{ width: 173 }}>
                                                <input name="DiameterValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="DiameterType">
                                                    <option value="S;mm;0">mm</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="DiameterError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ textAlign: "center" }}>
                                    <br />
                                    <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                                </div>
                                <table className="outputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Resultaat</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Druk einde leiding
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="PressurePipeEndValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="PressurePipeEndType" defaultValue={"S;bar(g);2"}>
                                                    <option value="S;bar(g);2">bar(g)</option>
                                                    <option value="S;bar(a);2">bar(a)</option>
                                                    <option value="S;kPa(g);2">kPa(g)</option>
                                                    <option value="S;kPa(a);2">kPa(a)</option>
                                                    <option value="S;MPa(g);2">MPa(g)</option>
                                                    <option value="S;MPa(a);2">MPa(a)</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="PressurePipeEndError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Stroomsnelheid begin leiding
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="FlowspeedPipeStartValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="FlowspeedPipeStartType">
                                                    <option value="S;m/s;1">m/s</option>
                                                    <option value="S;km/h;1">km/h</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="FlowspeedPipeStartError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
