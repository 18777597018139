import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class NavigationMenu extends Component {
    // Public functions

    public render(): JSX.Element {
        return (
            <div className="container" style={{ textAlign: "center" }}>
                <div id="nmpNavigationMenus">
                    <ul>
                        <li>
                            <Link to="/leidingdiameter-bij-gegeven-drukverlies/734">
                                Leidingdiameter bij gegeven drukverlies
                            </Link>
                        </li>
                        <li>
                            <Link to="/leidingdiameter-bij-gegeven-stroomsnelheid/735">
                                Leidingdiameter bij gegeven stroomsnelheid
                            </Link>
                        </li>
                        <li>
                            <Link to="/drukval-in-een-stoomleiding/736">Drukval in een stoomleiding</Link>
                        </li>
                        <li>
                            <Link to="/minimale-pijpdiameter-condensaatleiding/737">
                                Minimale pijpdiameter condensaatleiding
                            </Link>
                        </li>
                        <li>
                            <Link to="/naverdampstoom-bij-condensaatafvoer/738">
                                Naverdampstoom bij condensaatafvoer
                            </Link>
                        </li>
                        <li>
                            <Link to="/kv-waarde-berekening-voor-verzadigd-stoom/739">
                                Kv waarde berekening voor verzadigd stoom
                            </Link>
                        </li>
                        <li>
                            <Link to="/kv-waarde-berekening-voor-oververhit-stoom/740">
                                Kv waarde berekening voor oververhit stoom
                            </Link>
                        </li>
                        <li>
                            <Link to="/kv-waarde-berekening-voor-water/741">Kv waarde berekening voor water</Link>
                        </li>
                        <li>
                            <Link to="/stoomkwaliteit-door-drukverlaging/742">Stoomkwaliteit door drukverlaging</Link>
                        </li>
                        <li>
                            <Link to="/opstart-en-gebruiksverliezen-van-een-stoomleiding/743">
                                Opstart en gebruiksverliezen van een stoomleiding
                            </Link>
                        </li>
                        <li>
                            <Link to="/impact-van-lucht-op-de-temperatuur-in-een-stoomsysteem/744">
                                Impact van lucht op de temperatuur in een stoomsysteem
                            </Link>
                        </li>
                        <li>
                            <Link to="/vermogen-naar-stoomverbruik/745">Vermogen naar stoomverbruik</Link>
                        </li>
                        <li>
                            <Link to="/stoomverbruik-van-een-warmtewisselaar/746">
                                Stoomverbruik van een warmtewisselaar
                            </Link>
                        </li>
                        <li>
                            <Link to="/verzadigde-stoomeigenschappen-naar-druk/747">
                                Verzadigde stoomeigenschappen naar druk
                            </Link>
                        </li>
                        <li>
                            <Link to="/verzadigde-stoomeigenschappen-naar-temperatuur/748">
                                Verzadigde stoomeigenschappen naar temperatuur
                            </Link>
                        </li>
                        <li>
                            <Link to="/stoomeigenschappen-naar-druk-en-temperatuur/749">
                                Stoomeigenschappen naar druk en temperatuur
                            </Link>
                        </li>
                        <li>
                            <Link to="/stoomeigenschappen-naar-druk-en-enthalpie/750">
                                Stoomeigenschappen naar druk en enthalpie
                            </Link>
                        </li>
                        <li>
                            <Link to="/omrekentabel-lengte/751">Omrekentabel lengte</Link>
                        </li>
                        <li>
                            <Link to="/omrekentabel-druk/752">Omrekentabel druk</Link>
                        </li>
                        <li>
                            <Link to="/omrekentabel-snelheid/753">Omrekentabel snelheid</Link>
                        </li>
                        <li>
                            <Link to="/omrekentabel-energie/754">Omrekentabel energie</Link>
                        </li>
                        <li>
                            <Link to="/omrekentabel-capaciteit/755">Omrekentabel capaciteit</Link>
                        </li>
                        <li>
                            <Link to="/omrekentabel-temperatuur/756">Omrekentabel temperatuur</Link>
                        </li>
                        <li>
                            <Link to="/omrekentabel-massa-debiet/757">Omrekentabel massa debiet</Link>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
