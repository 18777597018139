// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class PowerToSteamUsage extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Vermogen naar stoomverbruik</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1} style={{ lineHeight: "20.7999992370605px" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Deze calculator rekent het vermogen van een verbruiker om naar stoomverbruik. De
                                    input bestaat uit het vermogen van de verbruiker en de druk op het stoomnet. De
                                    output bestaat uit het stoomverbruik per uur.
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>
                                        Aannames: Deze berekening gaat er van uit dat de verbruiker een rendement van
                                        100% heeft, en de stoom verzadigd is en 100% droog is. Omdat dergelijke waardes
                                        in de praktijk zelden voorkomen is het verstandig om, afhankelijk van de
                                        daadwerkelijke situatie, een veiligheidsmarge te hanteren.
                                    </strong>
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="steamcalculation">
                        <form action={null} id="calculationl" method="post" noValidate="novalidate">
                            <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                            &nbsp;
                            <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                            <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                            <div className="steamcalculation">
                                <table className="inputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Invoer gegevens</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>
                                                <strong>Eenhedenstelsel</strong>
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="ChangeTypes">
                                                    <option value="S">SI(bar)</option>
                                                    <option value="I">Imperial</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>&nbsp;</td>
                                            <td style={{ width: 82 }}>&nbsp;</td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Vermogen</td>
                                            <td style={{ width: 173 }}>
                                                <input
                                                    name="PowerValue"
                                                    className="inputvalue"
                                                    style={{
                                                        backgroundImage: 'url("data:image/png',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundAttachment: "scroll",
                                                        backgroundSize: "16px 18px",
                                                        backgroundPosition: "98% 50%",
                                                    }}
                                                />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="PowerType" defaultValue={"kW"}>
                                                    <option value="kW">kW</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PowerError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Druk op het stoomnet</td>
                                            <td style={{ width: 173 }}>
                                                <input name="PressureSteamNetValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="PressureSteamNetType">
                                                    <option value="S;bar(g);5">bar(g)</option>
                                                    <option value="S;bar(a);5">bar(a)</option>
                                                    <option value="S;kPa(g);5">kPa(g)</option>
                                                    <option value="S;kPa(a);5">kPa(a)</option>
                                                    <option value="S;MPa(g);5">MPa(g)</option>
                                                    <option value="S;MPa(a);5">MPa(a)</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PressureSteamNetError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ textAlign: "center" }}>
                                    <br />
                                    <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                                </div>
                                <table className="outputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Resultaat</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Stoomverbruik
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="SteamUsageValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="SteamUsageType">
                                                    <option value="S;kg/h;0">kg/h</option>
                                                    <option value="S;kg/s;3">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="SteamUsageError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
