// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class DiameterAtAGivenVelocity extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Minimale leidingdiameter bij stroomsnelheid, droog verzadigd stoom</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1} style={{ lineHeight: "20.7999992370605px" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Deze calculator berekent de gewenste diameter bij een gegeven stroomsnelheid. De
                                    output bestaat uit twee suggesties voor de diameter van de leiding, een grotere
                                    diameter waarbij de stroomsnelheid aan het einde van de leiding onder de gewenste
                                    snelheid uit komt en een kleinere diameter waarbij de stroomsnelheid boven de
                                    gewenste snelheid uit komt.&nbsp;
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>
                                        Aannames: Bij deze berekening is ervan uitgegaan dat de drukval in de leiding
                                        geen invloed heeft op de stroomsnelheid. Een sterke daling van de druk zorgt
                                        ervoor dat de dichtheid afneemt, waardoor de stroomsnelheid toeneemt. Controleer
                                        daarom altijd de berekende druk aan het eind van de leiding.
                                    </strong>
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="steamcalculation">
                        <form action={null} id="calculationb" method="post" noValidate="novalidate">
                            <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                            &nbsp;
                            <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                            <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                            <div className="steamcalculation">
                                <table className="inputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Invoer gegevens</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>
                                                <strong>Eenhedenstelstel</strong>
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="ChangeTypes">
                                                    <option value="S">SI(bar)</option>
                                                    <option value="I">Imperial</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>&nbsp;</td>
                                            <td style={{ width: 82 }}>&nbsp;</td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Druk begin leiding</td>
                                            <td style={{ width: 173 }}>
                                                <input
                                                    name="PressurePipeStartValue"
                                                    className="inputvalue"
                                                    style={{
                                                        backgroundImage: 'url("data:image/png',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundAttachment: "scroll",
                                                        backgroundSize: "16px 18px",
                                                        backgroundPosition: "98% 50%",
                                                    }}
                                                />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="PressurePipeStartType" defaultValue={"S;bar(g)"}>
                                                    <option value="S;bar(g)">bar(g)</option>
                                                    <option value="S;bar(a)">bar(a)</option>
                                                    <option value="S;kPa(g)">kPa(g)</option>
                                                    <option value="S;kPa(a)">kPa(a)</option>
                                                    <option value="S;MPa(g)">MPa(g)</option>
                                                    <option value="S;MPa(a)">MPa(a)</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PressurePipeStartError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Debiet stoom</td>
                                            <td style={{ width: 173 }}>
                                                <input name="SteamFlowValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="SteamFlowType">
                                                    <option value="S;kg/h">kg/h</option>
                                                    <option value="S;kg/s">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="SteamFlowError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Lengte leiding</td>
                                            <td style={{ width: 173 }}>
                                                <input name="PipeLengthValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="PipeLengthType">
                                                    <option value="S;m">m</option>
                                                    <option value="S;cm">cm</option>
                                                    <option value="S;mm">mm</option>
                                                    <option value="S;nmi">nmi</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PipeLengthError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Doelsnelheid begin leiding</td>
                                            <td style={{ width: 173 }}>
                                                <input name="TargetFlowPipeStartValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="TargetFlowPipeStartType">
                                                    <option value="S;m/s;5">m/s</option>
                                                    <option value="S;km/h;5">km/h</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="TargetFlowPipeStartError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ textAlign: "center" }}>
                                    <br />
                                    <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                                </div>
                                <table className="outputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Resultaat</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Minimale diameter leiding
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="MinimalPipeDiameterValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="MinimalPipeDiameterType">
                                                    <option value="S;mm;0">mm</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="MinimalPipeDiameterError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                colSpan={4}
                                                style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}
                                            >
                                                <hr />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Dichtstbijzijnde kleinere diameter leiding
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="NearestSmallerPipeDiameterValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="NearestSmallerPipeDiameterType">
                                                    <option value="S;mm;0">mm</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="NearestSmallerPipeDiameterError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Druk einde leiding
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="PressurePipeEndSmallerValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="PressurePipeEndSmallerType">
                                                    <option value="S;bar(g);2">bar(g)</option>
                                                    <option value="S;bar(a);2">bar(a)</option>
                                                    <option value="S;kPa(g);2">kPa(g)</option>
                                                    <option value="S;kPa(a);2">kPa(a)</option>
                                                    <option value="S;MPa(g);2">MPa(g)</option>
                                                    <option value="S;MPa(a);2">MPa(a)</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="PressurePipeEndSmallerError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Stroomsnelheid begin leiding
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="FlowspeedPipeStartSmallerValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="FlowspeedPipeStartSmallerType">
                                                    <option value="S;m/s;1">m/s</option>
                                                    <option value="S;km/h;1">km/h</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="FlowspeedPipeStartSmallerError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                colSpan={4}
                                                style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}
                                            >
                                                <hr />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Dichtstbijzijnde grotere diameter leiding
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="NearestLargerPipeDiameterValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="NearestLargerPipeDiameterType">
                                                    <option value="S;mm;0">mm</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="NearestLargerPipeDiameterError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Druk einde leiding
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="PressurePipeEndLargerValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="PressurePipeEndLargerType">
                                                    <option value="S;bar(g);2">bar(g)</option>
                                                    <option value="S;bar(a);2">bar(a)</option>
                                                    <option value="S;kPa(g);2">kPa(g)</option>
                                                    <option value="S;kPa(a);2">kPa(a)</option>
                                                    <option value="S;MPa(g);2">MPa(g)</option>
                                                    <option value="S;MPa(a);2">MPa(a)</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="PressurePipeEndLargerError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Stroomsnelheid begin leiding
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="FlowspeedPipeStartLargerValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="FlowspeedPipeStartLargerType">
                                                    <option value="S;m/s;1">m/s</option>
                                                    <option value="S;km/h;1">km/h</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="FlowspeedPipeStartLargerError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
