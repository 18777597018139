// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class SteamUsageOfAHeatExchanger extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Stoomverbruik van een warmtewisselaar</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1} style={{ lineHeight: "20.7999992370605px" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Deze calculator berekent het stoomverbruik van een warmtewisselaar. De input is de
                                    druk op het stoomnet, het debiet aan te verwarmen medium, het te verwarmen medium en
                                    het aantal graden dat het medium verwarmd wordt. De output bestaat uit het
                                    stoomverbruik per uur.
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>
                                        Aannames: Deze berekening gaat er van uit dat de verbruiker een rendement van
                                        100% heeft, en de stoom verzadigd en 100% droog is. Omdat dergelijke waardes in
                                        de praktijk zelden voorkomen is het verstandig om, afhankelijk van de
                                        daadwerkelijke situatie een veiligheidsmarge te hanteren.
                                    </strong>
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="steamcalculation">
                        <form action={null} id="calculationm" method="post" noValidate="novalidate">
                            <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                            &nbsp;
                            <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                            <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                            <div className="steamcalculation">
                                <table className="inputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Invoer gegevens</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>
                                                <strong>Eenhedenstelsel</strong>
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="ChangeTypes">
                                                    <option value="S">SI(bar)</option>
                                                    <option value="I">Imperial</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>&nbsp;</td>
                                            <td style={{ width: 82 }}>&nbsp;</td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Druk op het stoomnet</td>
                                            <td style={{ width: 173 }}>
                                                <input
                                                    name="PressureSteamNetValue"
                                                    className="inputvalue"
                                                    style={{
                                                        backgroundImage: 'url("data:image/png',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundAttachment: "scroll",
                                                        backgroundSize: "16px 18px",
                                                        backgroundPosition: "98% 50%",
                                                    }}
                                                />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="PressureSteamNetType" defaultValue={"S;bar(a)"}>
                                                    <option value="S;bar(a)">bar(a)</option>
                                                    <option value="S;bar(g)">bar(g)</option>
                                                    <option value="S;kPa(a)">kPa(a)</option>
                                                    <option value="S;kPa(g)">kPa(g)</option>
                                                    <option value="S;MPa(a)">MPa(a)</option>
                                                    <option value="S;MPa(g)">MPa(g)</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PressureSteamNetError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Debiet</td>
                                            <td style={{ width: 173 }}>
                                                <input name="FlowValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="FlowType">
                                                    <option value="S;kg/h">kg/h</option>
                                                    <option value="S;kg/s">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="FlowError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Te verwarmen medium</td>
                                            <td style={{ width: 173 }}>
                                                <select name="ToBeHeatedMediumValue" className="inputvalue">
                                                    <option value="amoniak">Amoniak</option>
                                                    <option value="benzine">Benzine</option>
                                                    <option value="ethanol">Ethanol</option>
                                                    <option value="Glycerine">Glycerine</option>
                                                    <option value="Glycol">Glycol</option>
                                                    <option value="Kerosine">Kerosine</option>
                                                    <option value="Methanol">Methanol</option>
                                                    <option value="Olijfolie">Olijfolie</option>
                                                    <option value="Parafine">Parafine</option>
                                                    <option value="schoonwater">Schoon water</option>
                                                    <option value="zeewater">Zeewater</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 82 }}>&nbsp;</td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="ToBeHeatedMediumError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Graden verwarming</td>
                                            <td style={{ width: 173 }}>
                                                <input name="DegreesHeatedValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="DegreesHeatedType">
                                                    <option value="C">°C</option>
                                                    <option value="K">K</option>
                                                    <option value="F">°F</option>
                                                    <option value="R">°R</option>
                                                    <option value="Re">°Re</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="DegreesHeatedError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ textAlign: "center" }}>
                                    <br />
                                    <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                                </div>
                                <table className="outputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Resultaat</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Stoom per periode
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="kgSteamPerHourValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="kgSteamPerHourType">
                                                    <option value="S;kg/h;0">kg/h</option>
                                                    <option value="S;kg/s;3">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="kgSteamPerHourError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
