// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class StartUpAndOperatingLossesOfASteamPipe extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Opstartverlies &amp; gebruiksverliezen stoomleiding</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1} style={{ lineHeight: "20.7999992370605px" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Deze calculator berekent de opstart en gebruiksverliezen van een stoomleiding in
                                    kilogrammen stoom per uur. De input bestaat uit het type, maat en lengte van de
                                    leiding, de stoomdruk, omgevingstemperatuur en opstarttijd. De output is het
                                    opstartverlies en het gebruiksverlies van de leiding zonder isolatie, met 50mm
                                    isolatie, 75mm isolatie en 100mm isolatie.
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>
                                        Aannames opstartverliezen: De berekening voor de opstartverliezen berekent de
                                        hoeveelheid stoom die nodig is om de leiding op temperatuur te krijgen. Hierbij
                                        wordt geen rekening gehouden met gebruiksverliezen tijdens het opstarten.
                                    </strong>
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>
                                        Aannames gebruiksverliezen: De berekening voor gebruiksverliezen gaat er van uit
                                        dat de stoomleiding zich in een binnenruimte bevindt, horizontaal loopt en
                                        dusdanig ver van het plafond zit zodat dit geen invloed heeft. Daarnaast wordt
                                        er van uit gegaan dat leiding 100% goed geïsoleerd is. Mocht de leiding zich
                                        buiten, al dan niet in de regen bevinden, of mocht de leiding niet goed
                                        geïsoleerd zijn, dan kunnen de daadwerkelijke verliezen afwijken.
                                    </strong>
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="steamcalculation">
                        <form action={null} id="calculationj" method="post" noValidate="novalidate">
                            <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                            &nbsp;
                            <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                            <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                            <div className="steamcalculation">
                                <table className="inputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Invoer gegevens</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>
                                                <strong>Eenhedenstelsel</strong>
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="ChangeTypes">
                                                    <option value="S">SI(bar)</option>
                                                    <option value="I">Imperial</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                            <td style={{ width: 173 }}>&nbsp;</td>
                                            <td style={{ width: 82 }}>&nbsp;</td>
                                            <td style={{ width: 299 }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Type leiding</td>
                                            <td style={{ width: 173 }}>
                                                <select name="PipeTypeValue" className="inputvalue">
                                                    <option value="40Schedule">Schedule 40</option>
                                                    <option value="80Schedule">Schedule 80</option>
                                                    <option value="160Schedule">Schedule 160</option>
                                                    <option value="EN 10255-H">EN 10255-H</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 82 }}>&nbsp;</td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PipeTypeError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>
                                                Maat leiding <em>(EN 10255-H t/m DN100)</em>
                                            </td>
                                            <td style={{ width: 173 }}>
                                                <select name="PipeSizeValue" className="inputvalue">
                                                    <option value="DN15">DN15</option>
                                                    <option value="DN20">DN20</option>
                                                    <option value="DN25">DN25</option>
                                                    <option value="DN32">DN32</option>
                                                    <option value="DN40">DN40</option>
                                                    <option value="DN50">DN50</option>
                                                    <option value="DN65">DN65</option>
                                                    <option value="DN80">DN80</option>
                                                    <option value="DN100">DN100</option>
                                                    <option value="DN125">DN125</option>
                                                    <option value="DN150">DN150</option>
                                                    <option value="DN200">DN200</option>
                                                    <option value="DN250">DN250</option>
                                                    <option value="DN300">DN300</option>
                                                    <option value="DN350">DN350</option>
                                                    <option value="DN400">DN400</option>
                                                    <option value="DN450">DN450</option>
                                                    <option value="DN500">DN500</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 82 }}>&nbsp;</td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PipeSizeError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Lengte leiding</td>
                                            <td style={{ width: 173 }}>
                                                <input
                                                    name="PipeLengthValue"
                                                    className="inputvalue"
                                                    style={{
                                                        backgroundImage: 'url("data:image/png',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundAttachment: "scroll",
                                                        backgroundSize: "16px 18px",
                                                        backgroundPosition: "98% 50%",
                                                    }}
                                                />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="PipeLengthType">
                                                    <option value="S;m">m</option>
                                                    <option value="S;cm">cm</option>
                                                    <option value="S;mm">mm</option>
                                                    <option value="S;nmi">nmi</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="PipeLengthError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Stoomdruk</td>
                                            <td style={{ width: 173 }}>
                                                <input name="SteamPressureValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="SteamPressureType">
                                                    <option value="S;bar(g)">bar(g)</option>
                                                    <option value="S;bar(a)">bar(a)</option>
                                                    <option value="S;kPa(g)">kPa(g)</option>
                                                    <option value="S;kPa(a)">kPa(a)</option>
                                                    <option value="S;MPa(g)">MPa(g)</option>
                                                    <option value="S;MPa(a)">MPa(a)</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="SteamPressureError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Omgevingstemperatuur</td>
                                            <td style={{ width: 173 }}>
                                                <input name="EnvironmentalTemperatureValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="EnvironmentalTemperatureType">
                                                    <option value="C">°C</option>
                                                    <option value="K">K</option>
                                                    <option value="F">°F</option>
                                                    <option value="R">°R</option>
                                                    <option value="Re">°Re</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="EnvironmentalTemperatureError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 299 }}>Opstarttijd</td>
                                            <td style={{ width: 173 }}>
                                                <input name="StartupTimeValue" className="inputvalue" />
                                            </td>
                                            <td style={{ width: 82 }}>
                                                <select name="StartupTimeType">
                                                    <option value="h">u(u)r(en)</option>
                                                </select>
                                            </td>
                                            <td style={{ width: 299 }}>
                                                <div className="errormessage" id="StartupTimeError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ textAlign: "center" }}>
                                    <br />
                                    <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                                </div>
                                <table className="outputvalues">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <span style={{ fontSize: 16 }}>
                                                    <strong>Resultaat</strong>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Opstartverlies stoom
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="StartUpLossSteamValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="StartUpLossSteamType">
                                                    <option value="S;kg/h;0">kg/h</option>
                                                    <option value="S;kg/s;3">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="StartUpLossSteamError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Gebruiksverlies, geen isolatie
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="StartUpLossNoInsulationValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="StartUpLossNoInsulationType">
                                                    <option value="S;kg/h;0">kg/h</option>
                                                    <option value="S;kg/s;3">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="StartUpLossNoInsulationError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Gebruiksverlies, 50mm isolatie
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="StartUpLoss50mmInsulationValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="StartUpLoss50mmInsulationType">
                                                    <option value="S;kg/h;0">kg/h</option>
                                                    <option value="S;kg/s;3">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="StartUpLoss50mmInsulationError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Gebruiksverlies, 75mm isolatie
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="StartUpLoss75mmInsulationValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="StartUpLoss75mmInsulationType">
                                                    <option value="S;kg/h;0">kg/h</option>
                                                    <option value="S;kg/s;3">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="StartUpLoss75mmInsulationError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                Gebruiksverlies, 100mm isolatie
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                                <input name="StartUpLoss100mmInsulationValue" readOnly />
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                                <select name="StartUpLoss100mmInsulationType">
                                                    <option value="S;kg/h;0">kg/h</option>
                                                    <option value="S;kg/s;3">kg/s</option>
                                                </select>
                                            </td>
                                            <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                                <div className="errormessage" id="StartUpLoss100mmInsulationError">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
