// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class ConversionTableVelocity extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Omrekentabel snelheid</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Rekent snelheden om in de volgende eenheden: m/s, km/u, ft/s, ft/min, MPH
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <form action={null} id="calculationt" method="post" noValidate="novalidate">
                        <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                        &nbsp;
                        <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                        <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                        <input name="ChangeTypes" type="hidden" defaultValue="S" />
                        <div className="steamcalculation">
                            <table className="inputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Invoer gegevens</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 299 }}>Snelheid</td>
                                        <td style={{ width: 173 }}>
                                            <input
                                                name="SpeedValue"
                                                className="inputvalue"
                                                style={{
                                                    backgroundImage: 'url("data:image/png',
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundAttachment: "scroll",
                                                    backgroundSize: "16px 18px",
                                                    backgroundPosition: "98% 50%",
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: 82 }}>
                                            <select name="SpeedType">
                                                <option value="m/s">m/s</option>
                                                <option value="km/h">km/h</option>
                                                <option value="ft/s">ft/s</option>
                                                <option value="ft/min">ft/min</option>
                                                <option value="mph">mph</option>
                                            </select>
                                        </td>
                                        <td style={{ width: 299 }}>
                                            <div className="errormessage" id="SpeedError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ textAlign: "center" }}>
                                <br />
                                <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                            </div>
                            <table className="outputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Resultaat</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Snelheid in meters per seconde
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Speed0Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            m/s
                                            <input name="Speed0Type" type="hidden" defaultValue="S;m/s;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Speed0Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Snelheid in kilometers per uur
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Speed1Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            km/u
                                            <input name="Speed1Type" type="hidden" defaultValue="S;km/h;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Speed1Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Snelheid in feet per seconde
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Speed2Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            ft/s
                                            <input name="Speed2Type" type="hidden" defaultValue="S;ft/s;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Speed2Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Snelheid in feet per minuut
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Speed3Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            ft/min
                                            <input name="Speed3Type" type="hidden" defaultValue="S;ft/min;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Speed3Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Snelheid in mijlen per uur
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Speed4Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            mph
                                            <input name="Speed4Type" type="hidden" defaultValue="S;mph;4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Speed4Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
