// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class ConversionTablePressure extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Omrekentabel druk</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Rekent drukken om in de volgende eenheden: bar(g), bar(a), kPa(g), kPa(a), MPa(g),
                                    MPa(a), psi(g), psi(a)
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <form action={null} id="calculations" method="post" noValidate="novalidate">
                        <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                        &nbsp;
                        <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                        <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                        <input name="ChangeTypes" type="hidden" defaultValue="S" />
                        <div className="steamcalculation">
                            <table className="inputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Invoer gegevens</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 299 }}>Druk</td>
                                        <td style={{ width: 173 }}>
                                            <input
                                                name="PressureValue"
                                                className="inputvalue"
                                                style={{
                                                    backgroundImage: 'url("data:image/png',
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundAttachment: "scroll",
                                                    backgroundSize: "16px 18px",
                                                    backgroundPosition: "98% 50%",
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: 82 }}>
                                            <select name="PressureType" defaultValue={"bar(g)"}>
                                                <option value="bar(g)">bar(g)</option>
                                                <option value="bar(a)">bar(a)</option>
                                                <option value="kPa(g)">kPa(g)</option>
                                                <option value="kPa(a)">kPa(a)</option>
                                                <option value="MPa(g)">MPa(g)</option>
                                                <option value="MPa(a)">MPa(a)</option>
                                                <option value="psi(g)">psi(g)</option>
                                                <option value="psi(a)">psi(a)</option>
                                            </select>
                                        </td>
                                        <td style={{ width: 299 }}>
                                            <div className="errormessage" id="PressureError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ textAlign: "center" }}>
                                <br />
                                <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                            </div>
                            <table className="outputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Resultaat</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Druk in bar (overdruk)
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Pressure0Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            bar(g)
                                            <input name="Pressure0Type" type="hidden" defaultValue="S;bar(g);4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Pressure0Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Druk in bar (
                                            <span style={{ backgroundColor: "rgb(230, 244, 253)" }}>absoluut</span>)
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Pressure1Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            bar(a)
                                            <input name="Pressure1Type" type="hidden" defaultValue="S;bar(a);4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Pressure1Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Druk in kiloPascal (
                                            <span style={{ backgroundColor: "rgb(230, 244, 253)" }}>overdruk</span>)
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Pressure2Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            kPa(g)
                                            <input name="Pressure2Type" type="hidden" defaultValue="S;kPa(g);4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Pressure2Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Druk in kiloPascal (
                                            <span style={{ backgroundColor: "rgb(230, 244, 253)" }}>absoluut</span>)
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Pressure3Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            kPa(a)
                                            <input name="Pressure3Type" type="hidden" defaultValue="S;kPa(a);4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Pressure3Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Druk in megaPascal (
                                            <span style={{ backgroundColor: "rgb(230, 244, 253)" }}>overdruk</span>)
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Pressure4Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            MPa(g)
                                            <input name="Pressure4Type" type="hidden" defaultValue="S;MPa(g);4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Pressure4Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Druk in megaPascal (
                                            <span style={{ backgroundColor: "rgb(230, 244, 253)" }}>absoluut</span>)
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Pressure5Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            MPa(a)
                                            <input name="Pressure5Type" type="hidden" defaultValue="S;MPa(a);4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Pressure5Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Druk in pounds force per square inch (
                                            <span style={{ backgroundColor: "rgb(230, 244, 253)" }}>overdruk</span>)
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Pressure6Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            psi(g)
                                            <input name="Pressure6Type" type="hidden" defaultValue="S;psi(g);4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Pressure6Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Druk in pounds force per square inch (absoluut)
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="Pressure7Value" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            psi(a)
                                            <input name="Pressure7Type" type="hidden" defaultValue="S;psi(a);4" />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="Pressure7Error">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
