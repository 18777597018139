// @ts-nocheck
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButtonImage from "../../res/images/back_button_nl.png";
import CalculateButtonImage from "../../res/images/calculate_button_nl.png";

export default class SaturatedSteamTablesByTemperature extends Component {
    // Public functions

    public componentDidMount(): void {
        new window.SteamCalculator.Create().Activate();
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Verzadigde stoomeigenschappen naar temperatuur</h1>
                <div id="nmpContent">
                    <table border={0} cellPadding={1} cellSpacing={1} style={{ lineHeight: "20.7999992370605px" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <img src={BackButtonImage} style={{ float: "right", height: 30, width: 100 }} />
                                    </Link>
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Deze calculator zoekt de stoomeigenschappen van verzadigd stoom op aan de hand van
                                    de temperatuur. De input bestaat uit de druk op het stoomnet. De output bestaat uit
                                    de verzadigde stoomdruk, de enthalpie en dichtheid van zowel het deel dat vloeibaar
                                    is als het gasvormige deel en de verdampingsenergie.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mocht u vragen hebben over deze rekenmodule of stoomkundige berekeningen in het
                                    algemeen, neemt u dan contact op met onze productspecialisten via&nbsp;
                                    <a
                                        href="mailto:stoom@eriks.nl?subject=Vraag%20n.a.v.%20de%20stoomcalculator"
                                        style={{ lineHeight: "20.7999992370605px" }}
                                    >
                                        stoom@eriks.nl
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <form action={null} id="calculationo" method="post" noValidate="novalidate">
                        <input name="RequestLanguage" type="hidden" defaultValue="NL" />
                        &nbsp;
                        <input name="LoadingText" type="hidden" defaultValue="Wordt berekend" />
                        <input name="WrongInput" type="hidden" defaultValue="Ongeldige invoer" />
                        <div className="steamcalculation">
                            <table className="inputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Invoer gegevens</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 298 }}>&nbsp;</td>
                                        <td style={{ width: 175 }}>
                                            <strong>Eenhedenstelsel</strong>
                                        </td>
                                        <td style={{ width: 82 }}>
                                            <select name="ChangeTypes">
                                                <option value="S">SI(bar)</option>
                                                <option value="I">Imperial</option>
                                            </select>
                                        </td>
                                        <td style={{ width: 298 }}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 298 }}>&nbsp;</td>
                                        <td style={{ width: 175 }}>&nbsp;</td>
                                        <td style={{ width: 82 }}>&nbsp;</td>
                                        <td style={{ width: 298 }}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 298 }}>Temperatuur</td>
                                        <td style={{ width: 175 }}>
                                            <input
                                                name="TemperatureValue"
                                                className="inputvalue"
                                                style={{
                                                    backgroundImage: 'url("data:image/png',
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundAttachment: "scroll",
                                                    backgroundSize: "16px 18px",
                                                    backgroundPosition: "98% 50%",
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: 82 }}>
                                            <select name="TemperatureType">
                                                <option value="C">°C</option>
                                                <option value="K">K</option>
                                                <option value="F">°F</option>
                                                <option value="R">°R</option>
                                                <option value="Re">°Re</option>
                                            </select>
                                        </td>
                                        <td style={{ width: 298 }}>
                                            <div className="errormessage" id="TemperatureError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ textAlign: "center" }}>
                                <br />
                                <input src={CalculateButtonImage} style={{ height: 27, width: 182 }} type="image" />
                            </div>
                            <table className="outputvalues">
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <span style={{ fontSize: 16 }}>
                                                <strong>Resultaat</strong>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Verzadigde stoomdruk
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="SaturatedSteamPressureValue" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            <select name="SaturatedSteamPressureType">
                                                <option value="S;bar(g);2">bar(g)</option>
                                                <option value="S;bar(a);2">bar(a)</option>
                                                <option value="S;kPa(g);5">kPa(g)</option>
                                                <option value="S;kPa(a);5">kPa(a)</option>
                                                <option value="S;MPa(g);3">MPa(g)</option>
                                                <option value="S;MPa(a);3">MPa(a)</option>
                                            </select>
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="SaturatedSteamPressureError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} style={{ width: 299 }}>
                                            <hr />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 299 }}>
                                            <strong>Vloeistof</strong>
                                        </td>
                                        <td style={{ width: 173 }}>&nbsp;</td>
                                        <td style={{ width: 82 }}>&nbsp;</td>
                                        <td style={{ width: 299 }}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>Enthalpie</td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="EnthalpyValue" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            <select name="EnthalpyType">
                                                <option value="S;kJ/kg;2">kJ/kg</option>
                                            </select>
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="EnthalpyError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>Dichtheid</td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="DensityValue" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            <select name="DensityType">
                                                <option value="S;kg/m3;5">kg/m³</option>
                                            </select>
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="DensityError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>Entropie</td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="EntropyValue" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            <select name="EntropyType">
                                                <option value="S;kJ/kgK;4">kJ/kgK</option>
                                            </select>
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="EntropyError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} style={{ width: 299 }}>
                                            <hr />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 299 }}>
                                            <strong>Gas</strong>
                                        </td>
                                        <td style={{ width: 173 }}>&nbsp;</td>
                                        <td style={{ width: 82 }}>&nbsp;</td>
                                        <td style={{ width: 299 }}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Enthalpie gas
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="EnthalpyGasValue" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            <select name="EnthalpyGasType">
                                                <option value="S;kJ/kg;2">kJ/kg</option>
                                            </select>
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="EnthalpyGasError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Dichtheid gas
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="DensityGasValue" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            <select name="DensityGasType">
                                                <option value="S;kg/m3;5">kg/m³</option>
                                            </select>
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="DensityGasError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Entropie gas
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="EntropyGasValue" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            <select name="EntropyGasType">
                                                <option value="S;kJ/kgK;4">kJ/kgK</option>
                                            </select>
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="EntropyGasError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} style={{ width: 299 }}>
                                            <hr />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            Verdampingsenergie
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 173 }}>
                                            <input name="HeatOfEvaporationValue" readOnly />
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 82 }}>
                                            <select name="HeatOfEvaporationType">
                                                <option value="S;kJ/kg;2">kJ/kg</option>
                                            </select>
                                        </td>
                                        <td style={{ backgroundColor: "rgb(230, 244, 253)", width: 299 }}>
                                            <div className="errormessage" id="HeatOfEvaporationError">
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
