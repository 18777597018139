import React, { Component } from "react";
import stoomcalImage from "../res/images/stoomcal.png";
import { Link } from "react-router-dom";

export default class MainPage extends Component {
    // Public functions

    public render(): JSX.Element {
        return (
            <div className="container">
                <h1>Stoomcalculator</h1>

                <div id="nmpContent">
                    <table cellPadding={0} cellSpacing={0} style={{ border: 0, lineHeight: "24px", width: "900px" }}>
                        <tbody>
                            <tr>
                                <td colSpan={3} style={{ verticalAlign: "top", width: 440 }}>
                                    <p>
                                        <a id={"Top"} />
                                        De ERIKS stoomcalculator biedt een brede range aan calculaties, geschikt voor
                                        het rekenen aan uw stoomsysteem.&nbsp;
                                    </p>

                                    <p>
                                        Wij hebben de berekeningen in negen categoriën ingedeeld (klik op een koppeling
                                        om naar de berekeningen te navigeren):
                                    </p>

                                    <table
                                        cellPadding={1}
                                        cellSpacing={1}
                                        className="NMPContentTable2"
                                        style={{ border: 1, width: 900 }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "300px" }}>
                                                    <a href="#StoomleidingOntwerp">Stoomleiding ontwerp</a>
                                                </td>
                                                <td style={{ width: "300px" }}>
                                                    <a href="#StoomDrogen">Stoom drogen</a>
                                                </td>
                                                <td style={{ width: "300px" }}>
                                                    <a href="#Verbruiker">Effect van een verbruiker</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#CondensaatleidingOntwerp">Condensaatleiding ontwerp</a>
                                                </td>
                                                <td>
                                                    <a href="#Opstartverliezen">Opstart- en gebruiksverliezen</a>
                                                </td>
                                                <td>
                                                    <a href="#Stoomtabellen">Stoomtabellen</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#KvBerekeningen">
                                                        Kv berekeningen voor kleppen en openingen
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href="#EffectLucht">Effect van lucht in het stoomsysteem</a>
                                                </td>
                                                <td>
                                                    <a href="#Omrekentabellen">Omrekentabellen</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ verticalAlign: "top", width: 440 }}>
                                    <h3>
                                        <em>
                                            <a id={"StoomleidingOntwerp"}>
                                                <span style={{ color: "#000" }}>Stoomleiding ontwerp</span>
                                            </a>
                                        </em>
                                    </h3>

                                    <ul>
                                        <li>
                                            <Link to="leidingdiameter-bij-gegeven-drukverlies/734">
                                                Leidingdiameter bij gegeven drukverlies
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="leidingdiameter-bij-gegeven-stroomsnelheid/735">
                                                Leidingdiameter bij gegeven stroomsnelheid
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="drukval-in-een-stoomleiding/736">
                                                Drukval in een stoomleiding
                                            </Link>
                                        </li>
                                    </ul>

                                    <h3>
                                        <em>
                                            <a id={"CondensaatleidingOntwerp"}>
                                                <span style={{ color: "#000" }}>Condensaatleiding ontwerp</span>
                                            </a>
                                        </em>
                                    </h3>

                                    <ul>
                                        <li>
                                            <Link to="minimale-pijpdiameter-condensaatleiding/737">
                                                Minimale pijpdiameter condensaatleiding
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="naverdampstoom-bij-condensaatafvoer/738">
                                                Naverdampstoom bij condensaatafvoer
                                            </Link>
                                        </li>
                                    </ul>

                                    <h3>
                                        <em>
                                            <a id={"KvBerekeningen"}>
                                                <span style={{ color: "#000" }}>
                                                    Kv berekeningen voor kleppen en openingen
                                                </span>
                                            </a>
                                        </em>
                                    </h3>

                                    <ul>
                                        <li>
                                            <Link to="kv-waarde-berekening-voor-verzadigd-stoom/739">
                                                Kv waarde berekening voor verzadigd stoom
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="kv-waarde-berekening-voor-oververhit-stoom/740">
                                                Kv waarde berekening voor oververhitte stoom
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="kv-waarde-berekening-voor-water/741">
                                                Kv waarde berekening voor water
                                            </Link>
                                        </li>
                                    </ul>

                                    <h3>
                                        <em>
                                            <a id={"StoomDrogen"}>
                                                <span style={{ color: "#000" }}>Stoom drogen</span>
                                            </a>
                                        </em>
                                    </h3>

                                    <ul>
                                        <li>
                                            <Link to="stoom-drogen-door-drukverlaging-en-water-injectie/742">
                                                Stoomkwaliteit door drukverlaging
                                            </Link>
                                        </li>
                                    </ul>

                                    <h3>
                                        <em>
                                            <a id={"Opstartverliezen"}>
                                                <span style={{ color: "#000" }}>Opstart- en gebruiksverliezen</span>
                                            </a>
                                        </em>
                                    </h3>

                                    <ul>
                                        <li>
                                            <Link to="opstart-en-gebruiksverliezen-van-een-stoomleiding/743">
                                                Opstart en gebruiksverliezen van een stoomleiding
                                            </Link>
                                        </li>
                                    </ul>

                                    <h3>
                                        <em>
                                            <a id={"EffectLucht"}>
                                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                                    Effect van lucht in uw stoomsysteem
                                                </span>
                                            </a>
                                        </em>
                                    </h3>

                                    <ul>
                                        <li>
                                            <Link to="impact-van-lucht-op-de-temperatuur-in-een-stoomsysteem/744">
                                                Impact van lucht op de temperatuur in een stoomsysteem
                                            </Link>
                                        </li>
                                    </ul>

                                    <h3>
                                        <em>
                                            <a id={"Verbruiker"}>
                                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                                    Stoomverbruik van een verbruiker
                                                </span>
                                            </a>
                                        </em>
                                    </h3>

                                    <ul>
                                        <li>
                                            <Link to="vermogen-naar-stoomverbruik/745">
                                                Vermogen naar stoomverbruik
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="stoomverbruik-van-een-warmtewisselaar/746">
                                                Stoomverbruik van een warmtewisselaar
                                            </Link>
                                        </li>
                                    </ul>
                                </td>
                                <td style={{ verticalAlign: "top", width: 20 }}>&nbsp;</td>
                                <td style={{ verticalAlign: "top", width: 440 }}>
                                    <h3>
                                        <em>
                                            <a id={"Stoomtabellen"}>
                                                <span style={{ color: "#000" }}>Stoomtabellen</span>
                                            </a>
                                        </em>
                                    </h3>

                                    <ul>
                                        <li>
                                            <Link to="verzadigde-stoomeigenschappen-naar-druk/747">
                                                Verzadigde stoomeigenschappen naar druk
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="verzadigde-stoomeigenschappen-naar-temperatuur/748">
                                                Verzadigde stoomeigenschappen naar temperatuur
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="stoomeigenschappen-naar-druk-en-temperatuur/749">
                                                Stoomeigenschappen naar druk en temperatuur
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="stoomeigenschappen-naar-druk-en-enthalpie/750">
                                                Stoomeigenschappen naar druk en enthalpie
                                            </Link>
                                        </li>
                                    </ul>

                                    <h3>
                                        <em>
                                            <a id={"Omrekentabellen"}>
                                                <span style={{ color: "#000000" }}>Omrekentabellen</span>
                                            </a>
                                        </em>
                                    </h3>

                                    <ul>
                                        <li>
                                            <Link to="omrekentabel-lengte/751">Omrekentabel lengte</Link>
                                        </li>
                                        <li>
                                            <Link to="omrekentabel-druk/752">Omrekentabel druk</Link>
                                        </li>
                                        <li>
                                            <Link to="omrekentabel-snelheid/753">Omrekentabel snelheid</Link>
                                        </li>
                                        <li>
                                            <Link to="omrekentabel-energie/754">Omrekentabel energie</Link>
                                        </li>
                                        <li>
                                            <Link to="omrekentabel-capaciteit/755">Omrekentabel capaciteit</Link>
                                        </li>
                                        <li>
                                            <Link to="omrekentabel-temperatuur/756">Omrekentabel temperatuur</Link>
                                        </li>
                                        <li>
                                            <Link to="omrekentabel-massa-debiet/757">Omrekentabel massa debiet</Link>
                                        </li>
                                    </ul>
                                    <img
                                        alt=""
                                        src={stoomcalImage}
                                        style={{ float: "right", height: 280, width: 420 }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
